.Header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 1235px;
  height: 72px;
  border-bottom: solid 1px #16254c;
  align-items: center;
  position: relative;
  z-index: 1;
  .impersonated {
    position: absolute;
    top: 0;
    left: 0; 
    right: 0;
    z-index: 2;
    color: #ff6347;
    background-color: #fff;
    opacity: 0.75;
    text-align: center;
    padding: 5px 10px;
    font-size: 14px;
  }
  .HeaderContainer {
    display: flex;
    height: 100%;
    max-width: 1235px;
    min-width: 320px;
    width: 100%;
    flex-direction: row;
    flex-basis: 100%;
    flex-grow: 1;
    padding-bottom: 0;
    align-items: center;
    justify-content: space-between;
    .header-left {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .header-logo {
        display: grid;
        width: 75px;
        height: 55px;
        cursor: pointer;
        place-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .MainMenu {
        margin-left: 25px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        padding-top: 38px;
        a.menu-link {
          display: flex;
          align-items: center;
          height: 100%;
          user-select: none;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          color: #16254c;
          .divider {
            width: 40px;
            display: flex;
            justify-content: center;
            font-weight: normal !important;
          }
          &.active {
            font-weight: bold;
          }
        }
      }
    }

    .header-right {
      display: flex;
      flex-direction: row;
      height: 100%;
      margin-top: 17px;
      align-items: center;
      .help-menu {
        height: 53px;
        width: 83px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-right: solid 1px #15254c;
        a.help-link {
          display: block;
          width: 25px;
          height: 25px;
          background: url('../../assets/images/help_bubble.png') no-repeat;
          background-size: cover;
        }
      }
      .header-menu {
        display: flex;
        height: 100%;
        width: auto;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        .menu.user {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 52px;
          .welcome-text {
            padding: 0px 22px;
            text-align: right;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #15254c;
          }
          span.profile-thumb {
            display: block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: url('../../assets/images/avatar.svg') no-repeat;
            background-size: cover;
          }

          &:hover {
            background: #fff;
          }
        }
      }
    }
  }
}

.BurgerMenu {
  height: 48px;
  position: relative;
  display: none;

  .UserBurger {
      margin-right: 10px;
      margin-top: -2px;
  
      .menu.user {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        height: 52px;
  
        .welcome-text {
          color: #fff;
          font-size: 14px;
          padding: 0px 22px;
          text-align: right;
        }
  
        span.profile-thumb {
          display: block;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: url('../../assets/images/avatar.svg') no-repeat;
          background-size: cover;
        }
      }
  
      .bm-item-list {
        .menu.user {
          justify-content: flex-end;
          padding-right: 20px;
          padding-top: 30px;
        }
      }
    }
  .bm-icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    width: 48px;
    height: 48px;
    position: relative;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 14px 14px 0;
    font-size: 14px;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .bm-menu-wrap {
    top: 0;
    width: 220px !important;
    max-width: 220px !important;
  }

  .header-menu {
    display: flex;
    height: 60px;
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    .menu.user {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 52px;
      .welcome-text {
        color: #fff;
        font-size: 14px;
        padding: 0px 22px;
        text-align: right;
      }
      span.profile-thumb {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: url('../../assets/images/avatar.svg') no-repeat;
        background-size: cover;
      }
    }

    .dd-menu-items {
      font-size: 12px;
    }
  }

  .MainMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    width: 100%;
    padding-top: 20px;
    padding-right: 10px;

    a.menu-link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      margin-right: 20px;
      color: rgba(227, 227, 227, 0.8);
      font-size: 16px;
      user-select: none;
      padding: 10px 12px;
      .divider {
        display: none;
      }
      &.active {
        color: #6893fd;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Header {
    .HeaderContainer {
      padding: 0 15px;
      .header-left {
        .MainMenu {
          display: none;
        }
      }

      .header-right {
        display: none;
      }
    }
  }

  .BurgerMenu {
    display: flex;
  }
}

@media only screen and (max-width: 1040px) {
  .Header {
    .HeaderContainer {
      padding: 0 27px;
    }
  }
}
