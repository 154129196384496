.NoMatch {
  a.go-home,
  a.go-back {
    font-size: 20px;
    font-weight: bold;
  }

  a.go-home {
    margin-left: 20px;
  }
}
