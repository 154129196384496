.DropzoneUploader {
  display: flex;
  flex-direction: column;
  max-width: 1110px;
  min-height: 630px;
  width: 100%;
  .uploader-container {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    cursor: pointer;
    position: relative;
    transition: background 0.3s;
    &.active {
      background: rgba(0, 0, 0, 0.36);
    }
    div.drop-instruction {
      position: absolute;
      opacity: 0.6;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 15px;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      background-color: white;
      font-size: 40px;
      text-align: center;
      border: 5px dashed #cacaca;
      color: #cacaca;
      z-index: 1;
    }
    div.instruction-text {
      opacity: 0.6;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      font-family: 'HelveticaNeue', sans-serif;
      font-weight: bold;
      color: #16254c;
      font-size: 22px;
      text-align: center;
      i {
        margin-top: 30px;
        font-size: 150px;
        color: rgba(0, 0, 0, 0.3);
      }
    }
    .upload-list {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;

      .instruction-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        .ins-item-container {
          width: 200px;
          height: 150px;
          background-color: #eee;
          border: 5px dashed #cacaca;
          display: flex;
          justify-content: center;
          align-items: center;
          .ins-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .text {
              font-size: 14px;
              line-height: 14px;
              width: 160px;
              text-align: center;
            }
            font-size: 50px;
            color: #cacaca;
            line-height: 60px;
          }
        }
      }
      .upload-item {
        display: flex;
        flex-direction: column;
        padding: 10px;
        .upload-item-container {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          width: 200px;
          height: 150px;
          align-items: center;
          justify-content: center;
          background: #eee;
          background-image: url('../../../assets/images/asset_base_logo_with_text.svg');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          color: #fff;
          .overlay {
            width: 100%;
            height: 100%;
            padding: 15px;
            background-color: rgba(0, 0, 0, 0.32);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: relative;
            .uploading {
              margin: 10px 0;
              visibility: hidden;
            }
            &.uploading {
              pointer-events: none;
              .uploading {
                visibility: visible;
              }
            }
            .remove-item {
              position: absolute;
              top: 0px;
              right: 5px;
              z-index: 10;
              padding: 5px;
              font-size: 18px;
              cursor: pointer;
              color: #fff;
              display: block;
            }
            .move-item {
              position: absolute;
              top: 0px;
              left: 5px;
              z-index: 10;
              padding: 5px;
              font-size: 18px;
              cursor: pointer;
              color: #fff;
              display: block;
            }
          }
          .file-name {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.progress-line {
  width: 100%;
  max-width: 1110px;
  height: 5px;
  position: absolute;
  .background {
    width: 100%;
    height: 5px;
    border-radius: 0px;
    background: rgba(0, 0, 0, 0);
    overflow: hidden;
    .meter {
      height: 100%;
      background: #2db7f5;
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      transition: width 0.5s;
    }
  }
}

.TitleInput {
  display: flex;
  flex-direction: row;
  height: 25px;
  position: relative;

  input {
    flex-basis: 100%;
    text-align: center;
    background: none;
    border: none;
  font-family: 'HelveticaNeue', sans-serif;
  font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    padding: 0px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .edit-button {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 0px;
  }

  &.editing {
    input {
      background: rgba(255, 255, 255, 0.5);
      outline: none !important;
      cursor: default;
    }
  }

  &.loading {
    pointer-events: none;
    opacity: 0.3;
    .edit-button {
      flex-basis: 25px;
    }
  }
}
