.SharedCustomers {
  padding-top: 45px;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  .header-section {
    padding: 20px 0px;
    .title {
      font-size: 23px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      margin-bottom: 20px;
      display: inline-block;
      text-transform: uppercase;
    }
  }
  .customers-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px auto;

    .customer-row {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 10px 15px;
      border-bottom: solid 2px #cdcdcd;
      
      .customer-avatar {
        margin-right: 20px;
        img {
          height: 70px;
        }
      }
      
      .customer-info {
        display: flex;
        flex-direction: column;
        padding-top: 12px;
        padding-bottom: 12px;
      }
      .customer-name {
        padding-right: 5px;
        font-weight: bold;
      }
      .customer-shard-properties {
        margin-top: 15px;
      }
    }
  }
}

.SharedCustomersPage {
  flex: 1;
  width: 100%;
}

.customer-action {
  margin-left: auto;
  display: flex;
  align-items: end;
  .properties-shared-btn {
      cursor: pointer;
      font-size: 20px;
      border: none;
      background: #fff;
  }
}

.customer-properties {
  width: 100%;
  min-height: 440px;
  max-height: 640px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  overflow-x: auto;

  .customer-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    .customer-avatar {
        margin-right: 20px;
        img {
          height: 70px;
        }
      }
      
      .customer-info {
        display: flex;
        flex-direction: column;
        padding-top: 12px;
        padding-bottom: 12px;
      }
      .customer-name {
        padding-right: 5px;
        font-weight: bold;
      }
  }
  .customer-properties-list {
    border: solid 1px #000;
    width: 100%;
    flex-grow: 1;
    flex-basis: initial;
    overflow-y: auto;
    ul {
      list-style: none;
      padding-left: 10px;
      > li {
        margin-bottom: 10px;
        vertical-align: middle;
        input[type=checkbox] {
          width: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
    }
  }
  .customer-footer {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.SharedCustomersPage {
  .popupbox-wrapper {
      min-width: 50%;
  }
}