.CheckBox {
  display: flex;
  justify-content: flex-start;
  align-items: top;
  .checkbox-box {
    width: 42px;
    height: 42px;
    margin-right: 20px;
    border: 1px solid #16254c;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &.checked {
      background-image: url('./check_bg.svg');
      border: none;
    }
  }
  .checkbox-label {
    font-size: 30px;
    line-height: 1.07;
    text-align: left;
    color: #676767;
    width: 350px;
  }
}
