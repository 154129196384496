.PdfFlipbook {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  .plyr {
    display: none;
  }
  .head-nav-bar-container {
    background: #fff;
    display: flex;
    justify-content: center;
    width: 100vw;
  }
  .head-nav-bar {
    width: 100%;
    max-width: 1040px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .button {
      cursor: pointer;
    }
    .navigations {
      display: flex;
      .button {
        margin: 0 20px;
      }
    }

    .options {
      display: flex;
      min-width: 240px;
      .button {
        margin: 0 20px;
      }
    }
    .zoom-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 20px;
      .group-btn {
        padding: 5px 8px;
        background-color: #fff;
        cursor: pointer;
        border: solid 1px #4364a9;
        color: #4364a9;
        &:hover {
          opacity: 0.7;
        }

        &.disabled {
          opacity: 1;
        }
        &.toggle-zoom-btn {
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: none;
          font-size: 22px;
          padding: 0;
          margin-bottom: 4px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .pdf-flipbook {
    .outline-container {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;
      background: #fff;
      width: 100%;
      justify-content: center;
      background-color: #ccc;
      display: flex;
      align-items: center;
      .nav-button {
        display: inline-block;
        cursor: pointer;
        width: 30px;
        height: 40px;
        margin: 10px;

        span.ctm-icon {
          width: 30px;
          height: 40px;
        }
      }
      .thumb-carousel-container {
        display: flex;
        width: 90%;
        overflow: hidden;
        .thumb-carousel {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          transition: transform 0.5s;
          .thumbnail-container {
            margin: 10px 10px 3px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            //max-height: 120px;
            //max-width: 160px;
            img {
              border: solid 1px #aaa;
              //height: 100%;
            }
            &.active {
              img {
                box-shadow: 0 0 3px 2px rgba(70, 180, 215, 0.9);
              }
            }
            .page-number {
              font-size: 12px;
              color: #16254c;
            }
          }
        }
      }
    }
  }

  span.ctm-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: cover;
    background-position: center;

    &.ctm-arrow-left {
      background-image: url('../../assets/images/close-icon-outline.svg');
    }

    &.ctm-double-chevron-left {
      background-image: url('../../assets/images/double-chevron-left-circle.svg');
    }

    &.ctm-double-chevron-right {
      background-image: url('../../assets/images/double-chevron-right-circle.svg');
    }

    &.ctm-chevron-left {
      background-image: url('../../assets/images/chevron-left-circle.svg');
    }

    &.ctm-chevron-right {
      background-image: url('../../assets/images/chevron-right-circle.svg');
    }

    &.ctm-search {
      background-image: url('../../assets/images/search-circle.svg');
    }

    &.ctm-cloud {
      background-image: url('../../assets/images/cloud-circle.svg');
    }

    &.ctm-menu-left {
      background-image: url('../../assets/images/menu-left.svg');
    }

    &.ctm-menu-right {
      background-image: url('../../assets/images/menu-right.svg');
    }

    &.ctm-speaker-circle {
      background-image: url('../../assets/images/speaker-circle.svg');
      &.mute {
        background-image: url('../../assets/images/mute-circle.svg');
      }
    }

    &.ctm-fullscreen-circle {
      &.expand {
        background-image: url('../../assets/images/arrow-expand-circle.svg');
      }
      &.collapse {
        background-image: url('../../assets/images/arrow-collapse-circle.svg');
      }
    }
  }
  .page-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .PdfFlipbook {
    .head-nav-bar {
      padding: 10px;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      .zoom-section {
        //margin: 0 2px;
        //.group-btn {
        //padding: 4px;
        //}
      }
      .options {
        min-width: 190px;
        //min-width: unset;
        //.button {
        //margin: 2px;
        //}
      }
      .navigations {
        min-width: 190px;
        .button {
          margin: 8px;
        }
      }
    }
    .pdf-flipbook
      .outline-container
      .thumb-carousel-container
      .thumb-carousel
      .thumbnail-container {
      max-width: 80px;
      max-height: unset;
    }
  }
}

@media only screen and (max-width: 600px) {
  .PdfFlipbook {
    .head-nav-bar {
      padding: 10px;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      .zoom-section {
        margin: 0;
        .group-btn {
          display: none;
          &.toggle-zoom-btn {
            display: flex;
          }
        }
      }
      .options {
        min-width: unset;
        .button {
          margin: 6px;
        }
      }
      .navigations {
        min-width: unset;
        .button {
          margin: 6px;
        }
      }
    }
    .pdf-flipbook
      .outline-container
      .thumb-carousel-container
      .thumb-carousel
      .thumbnail-container {
      max-width: 80px;
      max-height: unset;
    }
  }
}
