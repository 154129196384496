.ExteriorTour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-top: 44px;
  .url-link-input {
    margin-top: 20px;
    input {
      height: 52px;
      border: solid 1px #000000;
			border-radius: 0;
    }
    label {
      font-size: 23px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #262e40;
    }
  }
  .exterior-upload-video {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    cursor: pointer;
    div.instruction-text {
      display: flex;
      width: 100%;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      font-family: 'HelveticaNeue', sans-serif;
      font-weight: bold;
      color: #16254c;
      font-size: 22px;
    }
    .upload-list {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      .upload-item {
        display: flex;
        padding: 10px;
        .upload-item-container {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          width: 200px;
          height: 150px;
          align-items: center;
          justify-content: center;
          background: #eee;
          background-image: url('../../../assets/images/video_thumbnail.svg');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          color: #fff;
          .overlay {
            width: 100%;
            height: 100%;
            padding: 5px;
            background: rgba(0, 0, 0, 0.32);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            .remove-item {
              position: absolute;
              top: 0px;
              right: 5px;
              z-index: 10;
              padding: 5px;
              font-size: 18px;
              cursor: pointer;
              color: #fff;
              display: none;
            }
            .move-item {
              position: absolute;
              top: 0px;
              left: 5px;
              z-index: 10;
              padding: 5px;
              font-size: 18px;
              cursor: pointer;
              color: #fff;
              display: none;
            }
            &:hover {
              .remove-item {
                display: block;
              }
              .move-item {
                display: block;
              }
            }
          }
          .file-name {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            margin-bottom: 60px;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .help {
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262e40;
  }
}


.new-folder-button {
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  > *:not(:last-child)  {
    margin-right: 10px;
  }
}

.folder-nav-bar {
  background: rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px;
  border-bottom: solid 1px;
  > *:not(:last-child)  {
    margin-right: 5px;
  }
  > button {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    &:disabled {
      cursor: default;
      opacity: 0.75;
    }
  }
  .up-btn {
    padding: 4px;
    span.fa {
        font-size: 20px;
      }
  }
    .home-btn {
      padding: 4px;
      span.fa {
        font-size: 20px;
      }
    }
  .folder-btn {
    font-size: 18px;
  }
}


 

  .folder-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    &.root {
        max-height: 600px;
        overflow-y: auto;
        background: rgba(0, 0, 0, 0.05);
      }

    .folder-title {
      cursor: pointer;
      width: 100%;
      text-align: left;
      padding-left: 5px;

      &:hover {
        background: rgba(0, 0, 0, 0.16);
      }
    }
        .folder-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 20px;
          width: 100%;
        }
  }