@import './styles/fontface.css';
@import './styles/font-awesome.css';
@import './styles/react-popupbox.css';
@import './styles/materialdesignicons.css';
@import './styles/react-dd-menu.css';
@import './styles/filepicker.css';
@import './styles/dropzone.css';
@import '~react-toastify/dist/ReactToastify.css';

@import 'assets/styles/main.scss';
@import 'assets/styles/DefaultLayout.scss';
@import 'assets/styles/LoginLayout.scss';
@import 'assets/styles/DashboardPage.scss';
@import 'assets/styles/NoMatch.scss';
@import 'assets/styles/SignInPage.scss';
@import 'assets/styles/CreatePropertyPage.scss';
@import 'assets/styles/SignUpLayout.scss';

div.select__single-value,
div.select__multi-value__label,
div.select__placeholder {
  font-size: 21px;
}


.swal2-icon {
  &.swal2-warning,
  &.swal2-info,
  &.swal2-input
  &.swal2-error,
  &.swal2-success {
    border-color: #375fac;
    color: #375fac;
    &::before {
    display: none;
  }
  }
}

.disabled-overlay {
  pointer-events: none;
  opacity: 0.5;
}

html, body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
}