.TermsAndConditions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 3px;
  max-width: 900px;
  h1.title {
  }
  .content {
    text-align: left;
    margin: 20px 0px;
    padding: 15px;
    border: solid 1px black;
    max-height: 400px;
    overflow-y: auto;
  }

  .CheckBox {
    .checkbox-label {
      font-size: 16px;
      width: unset;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-left: 20px;
    }

    .checkbox-box {
      cursor: pointer;
      margin-right: 0px;
      flex-shrink: 0;
    }
  }

  button.proceed-button {
    align-self: flex-end;
    display: block;
    width: 180px;
    text-align: center;
    font-size: 20px;
    background: #fff;
    border: solid 1px #375fac;
    height: 50px;
    color: #375fac;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background: #375fac;
      color: #fff;
      border: none;
    }

    &.loading {
      opacity: 0.7;
      cursor: default;
      &:hover {
        background: #375fac;
        color: #fff;
        border: solid 1px #375fac;
      }
    }

    &.disabled {
      background: #fff;
      color: #000;
      border: solid 1px black;
      pointer-events: none;
      opacity: 0.4;
    }
  }

  button.decline-button {
    margin-left: 15px;
    align-self: flex-end;
    display: block;
    width: 180px;
    text-align: center;
    font-size: 20px;
    background: #fff;
    border: solid 1px #375fac;
    height: 50px;
    color: #375fac;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
  }
.buttons-container {
  display: flex;
  width: 100%;
  padding: 15px 20px;
  justify-content: center;
}
}

@media only screen and (max-width: 480px) {
  .TermsAndConditions {
    h1.title {
    }
  }
}
@media only screen and (max-width: 768px) {
  .TermsAndConditions {
    max-width: calc(100% - 80px);
  }
}

.TermsAndConditionsPage {
  display: flex;
  justify-content: center;
}