.Favorite {
  
  .mdi-heart-outline {
    opacity: 0.5;
    &:before {
      content: "\2661";
    }
  }

  .mdi-heart {
    &:before {
      content: "\2665";
    }
  }
}
