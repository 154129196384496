.UserSubscription {
  padding-top: 45px;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  .header-section {
    padding: 20px 0px;
    .title {
      font-size: 23px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      margin-bottom: 20px;
      display: inline-block;
      text-transform: uppercase;
    }
  }
  
    .plans-list {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      position: relative;

      .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        opacity: 0.75;
        background: #fff;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-weight: bold;
      }
  
      .plans-item {
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        width: 250px;
        min-height: 350px;
  
        .plan-name {
          font-weight: bold;
          margin-bottom: 20px;
          margin-top: 15px;
          font-size: 18px;
          text-align: center;
        }
  
        .plan-desc {
          margin-bottom: 12px;
          font-size: 16px;
          text-align: center;
        }

        .plan-usage {
          margin-bottom: 12px;
          padding-top: 10px;
          font-size: 14px;
          text-align: center;
        }
  
        .plan-sign-up {
          margin-top: auto;
          margin-bottom: 10px;
          .current-plan {
            color: #fff;
            background-color: #ac2430;
            font-size: 18px;
            padding: 12px 24px;
            border-radius: 4px;
          }
          .btn-sign-up-now {
            font-size: 18px;
            cursor: pointer;
            border: solid 2px #ac2430a3;
            padding: 12px 18px;
            border-radius: 4px;
            background-color: #fff;
            color: #ac2430;
          }

          [disabled] {
            cursor: default;
            opacity: 0.5;
          }
        }
      }
    }
}

.UserSubscriptionPage {
  flex: 1;
  width: 100%;
}


.upgrade-subscription-payment {
  width: 480px;
  text-align: left;
}

.plan-cancel {
  .cancel-subscription-plan-btn {
      border: none;
      background: none;
      text-decoration: underline;
      color: #ac2430;
      text-underline-offset: 5px;
      font-size: 14px;
      font-family: 'HelveticaNeue', sans-serif;
      cursor: pointer;
    }
    .cancelling-subscription-desc {
      font-size: 14px;
      font-family: 'HelveticaNeue', sans-serif;
      text-align: center;
      color: slategray;
    }
}


.swal2-popup #swal2-content {
  white-space: pre-line;
}

.subscription-alert {
  text-align: center;
}