.ContentBox {
  min-width: 400px;
  width: 100%;
  padding: 67px 41px 26px 41px;
  border-radius: 2px;
  // box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
    margin-top: 40px;
      margin-bottom: 40px;
  background-color: #f1f1f1;
  position: relative;
  .box-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .logo {
      width: 157.3px;
      height: 120px;
      background-image: url('../../../assets/images/asset_base_logo_with_text.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-bottom: 39px;
    }
    .box-title {
      margin: 10px 0px;
      font-size: 20px;
      font-weight: 100;
      color: #000000;
    }
  }
}

@media only screen and (max-width: 425px) {
  .ContentBox {
    min-width: 375px;
  }
}
