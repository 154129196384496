.ContactUs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .box-inputs {
    width: 100%;
    .input-email,
    .input-first-name,
    .input-last-name,
    .input-message {
      input {
        height: 30px;
        border-radius: 3px;
        background-color: #ffffff;
        border: solid 1px #262e40;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        padding: 5px 14px;
        &:-webkit-autofill {
          background-color: #ffffff;
          -webkit-box-shadow: 0 0 0 30px white inset;
        }
      }
      textarea {
        border: solid 1px #262e40;
        border-radius: 3px;
        margin-top: 5px;
        margin-bottom: 10px;
        padding: 8px 10px;
        width: 100%;
        min-height: 120px;
        font-size: 14px;
        resize: none;
        &:focus {
          outline: none;
          border: solid 1px #262e40;
        }
      }
    }
  }

  .sign-in-link {
    margin: 15px 15px 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.67;
    color: #000000;
  }

  .error-container {
    width: 100%;
    max-width: 318px;
    overflow: hidden;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 10px;
  }
}
