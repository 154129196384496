#root {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

abbr {
  color: #be1e2d;
}

.DefaultLayout {
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'HelveticaNeue', sans-serif;
  &.not-support-screen {
    background: #fff;
    .not-support-content {
      padding: 20px;
    }
  }
  .PageContent {
    flex-basis: auto;
    flex-grow: 1;
    width: 100%;
    max-width: 1040px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding-bottom: 65px;
  }

  .scroll-top-button {
    position: fixed;
    right: 50px;
    bottom: 50px;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.3s;
    &.show {
      right: 50px;
      opacity: 0.5;
      .btn-scroll {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border: 1px solid #979797;
          color: #979797;
        }
        .label {
          color: #979797;
          font-size: 14px;
        }
      }
    }
    &.hide {
      right: -50px;
      opacity: 0;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@media only screen and (max-width: 1040px) {
  .PageContent {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .PageContent {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 414px) {
  .PageContent {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.swal2-popup {
  .swal2-header {
    .swal2-icon {
      &.swal2-warning {
        border-color: rgba(55, 95, 172, 0.3) !important;
      }
      .swal2-icon-text {
        color: rgb(55, 95, 172) !important;
      }
      .swal2-success-line-long {
        background-color: rgb(55, 95, 172) !important;
      }
      .swal2-success-line-tip {
        background-color: rgb(55, 95, 172) !important;
      }
      .swal2-success-ring {
        border: 0.25em solid rgba(55, 95, 172, 0.3) !important;
      }
    }
  }

  .swal2-actions {
    .swal2-confirm,
    .swal2-cancel {
      &.swal2-styled {
        outline: 0 !important;
        user-select: none !important;
        border: solid 1px #375fac !important;
        background: #ffffff !important;
        font-size: 16px !important;
        font-weight: lighter !important;
        color: #375fac !important;
        &:hover {
          background: #375fac !important;
          color: #fff !important;
        }
        &:focus {
          box-shadow: none !important;
          outline: 0 !important;
        }
      }
    }
  }
}
