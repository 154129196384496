.DirectUploadSingleImage {
  display: block;
  width: 200px;
  height: 200px;
  position: relative;
  background-color: #d8d8d8;
  position: relative;
  .text-placeholder {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 70px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    align-self: flex-end;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &.placeholder,
  &.preview-edit-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #cccccc;

    label.preview {
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/asset_base_logo_with_text.svg');
      background-size: 50%;
    }
  }

  label.preview {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: background-image 0.5s;
    input[type='file'] {
      display: none;
    }
    &.blur {
      filter: blur(3px) grayscale(100);
    }
    .center-text {
      font-size: 21px;
      font-weight: bold;
      user-select: none;
    }
  }
  .uploading {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    i {
      color: #fff;
    }
  }

  .crop-button {
    position: absolute;
    right: 5px;
    top: 10px;
    z-index: 1;
    padding: 5px 7px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 22px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
