.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1235px;
  flex-basis: 40px;
  max-height: 40px;
  height: 40px;
  align-items: center;
  border-top: 1px solid #16254c;
  background: #ffffff;
  font-size: 14px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
    
  }
  .footer-content {
    color: #4a4a4a;
  }

  .footer-menu {
    display: inline-flex;
    .MainMenu {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    
        a.menu-link {
          display: flex;
          align-items: center;
          height: 100%;
          user-select: none;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          color: #16254c;
    
          .divider {
            width: 20px;
            display: flex;
            justify-content: center;
            font-weight: normal !important;
          }
    
          &.active {
            font-weight: bold;
          }
        }
      }
  }
}
