.HelpPage {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-grow: 1;
  justify-content: flex-start;
  background: #fff;
  width: 100%;
  .Help {
    width: 100%;
    max-width: 1040px;
    padding-top: 60px;
    padding-bottom: 100px;
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #16254c;
    }

    .sub-title {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.71;
      color: #373a3c;
    }

    .separate-line {
      background-color: #d8d8d8;
      height: 1px;
      flex-grow: 1;
      margin-top: 30px;
      margin-bottom: 25px;
    }
    .content {
      text-align: justify;
      padding: 20px 20px 20px 60px;
      font-size: 14px;
      color: #373a3c;
      line-height: 1.71;
      background-color: white;
    }
  }
}

@media only screen and (max-width: 540px) {
  .HelpPage {
    .Help {
      .content {
        padding-left: 48px;
      }
    }
  }
}
