.UserSetting {
  padding-top: 45px;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  .header-section {
    padding: 20px 0px;
    .title {
      font-size: 23px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      margin-bottom: 20px;
      display: inline-block;
      text-transform: uppercase;
    }
  }
  .settings-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px auto;

    .setting-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 15px;
      border: solid 1px #cdcdcd;
      border-bottom-width: 0px;
      
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-width: 1px;
      }
      .setting-name {
        padding-right: 5px;
        i {
          font-size: 12px;
          color: #777;
        }
      }
    }
  }
}

.UserSettingPage {
  flex: 1;
  width: 100%;
}
