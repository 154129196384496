.DashboardPage, .CreatePropertyPage, .ReportPage, .HelpPage, .UserProfilePage, .SharedCustomersPage, .UserSubscriptionPage, .UserSettingPage, .PaymentHistoryPage{
  > .title {
    display: none;
    line-height: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .DashboardPage, .CreatePropertyPage,
    .ReportPage, .HelpPage, .UserProfilePage, .SharedCustomersPage, .UserSubscriptionPage, .UserSettingPage, .PaymentHistoryPage {
    > .title {
      display: flex;
      line-height: 20px;
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .UserProfilePage, .SharedCustomersPage, .UserSubscriptionPage, .UserSettingPage, .PaymentHistoryPage {
    padding-top: 0px !important;
    width: 100%;
    .header-section {
      display: none;
    }
  }

  .PaymentHistory, .SharedCustomers, .UserSetting, .UserSubscription {
    padding-top: 0px !important;
  }
}
