/* Font Lato Regular */
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.ttf');
}

/* Font Lato Light */
@font-face {
  font-family: 'Lato-Light';
  src: url('../fonts/Lato-Light.ttf');
}

/* Font Lato Bold */
@font-face {
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato-Bold.ttf');
}

/* Font HelveticaNeue */
@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeue.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeue Bold.ttf');
  font-weight: bold;
}
