.ResetPassword {
  .box-inputs {
    width: 100%;
    // input {
    //   height: 30px;
    //   border-radius: 3px;
    //   background-color: #ffffff;
    //   border: solid 1px #262e40;
    //   font-family: 'Poppins', sans-serif;
    //   font-size: 14px;
    //   padding: 5px 14px;
    // }
  }
}
