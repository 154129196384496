.List {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: #fff;
	flex-wrap: wrap;
  .Item {
    width: auto;
    display: flex;
    flex-direction: row;
  }
  .loading {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
