.PropertyView {
  padding-bottom: 50px;
  width: 100%;
  .edit-container {
    min-height: 58px;
  }
  .property-view-container {
  }
  .edit-button-container {
    .btn-property {
      width: 80px;
      margin-right: 0px;
    }
  }

  .navigate-property-buttons {
    .btn {
      border: none;
      font-size: 16px;
      font-weight: 700;
      width: 160px;
      height: auto;
      text-transform: uppercase;
      color: #9a9a9a;
      line-height: 1.2;
      &:hover {
        background: none;
      }
      &:focus,
      &:active,
      &:visited {
        outline: none !important;
      }
      i {
        width: 40px;
        height: 40px;
        margin: 2px 5px;
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;
        flex-basis: 40px;
        min-width: 40px;
        opacity: 0.5;
      }
      &.forward {
        i {
          background-image: url('../../assets/images/next-square.svg');
        }
      }

      &.backward {
        i {
          background-image: url('../../assets/images/back-square.svg');
        }
      }
    }
  }
}

.BasicView {
  .main-content {
    .thumbnail {
      height: 320px;
      min-height: 320px;
      flex-basis: 480px;
      width: 480px;
      min-width: 480px;
      background-image: url('../../assets/images/asset_base_logo_with_text.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border: solid 1px #e6e6e6;
    }
    .thumbnail-placeholder {
      height: 320px;
      min-height: 320px;
      flex-basis: 480px;
      width: 480px;
      min-width: 480px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #cccccc;

      .image {
        display: block;
        width: 216px;
        height: 162px;
        opacity: 0.6;
        background: url('../../assets/images/asset_base_logo_with_text.svg') no-repeat;
        background-size: contain;
      }
    }
    .body-content {
      padding-left: 30px;
      overflow: hidden;
      .name {
        font-family: 'HelveticaNeue', sans-serif;
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #16254c;
      }
      .info {
        .row {
          display: flex;
          flex-direction: row;
          width: 100%;
          &:first-child {
            margin-top: 20px;
          }
          label {
            min-width: 150px;
            font-family: 'HelveticaNeue', sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #373a3c;
          }

          .content {
            flex-basis: 100%;
            flex-grow: 1;
            overflow: hidden;
            font-family: 'HelveticaNeue', sans-serif;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #373a3c;
          }
        }
      }
    }
  }
  .description {
    .title {
      font-family: 'HelveticaNeue', sans-serif;
      font-size: 24px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.79;
      letter-spacing: normal;
      color: #16254c;
    }
    .description-content {
      text-align: justify;
      font-family: 'HelveticaNeue', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #373a3c;
      margin-bottom: 0px;
      margin-top: 20px;
      white-space: pre-line;
    }
  }
}

.hz-line {
  display: block;
  width: 100%;
  height: 2px;
  background: #d8d8d8;
  margin: 30px 0px;
}

.Document {
  width: 100%;
  .content {
    flex-grow: 1;
  }

  .item {
    border: solid 1px #eceeef;
    border-top: none;
    border-left-color: #d8d8d8;
    border-right-color: #d8d8d8;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #373a3c;
    .file-name {
      margin-left: 35px;
      flex-grow: 1;
    }
    .group-download {
      padding-right: 20px;
      justify-content: space-between;
      width: 130px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .CollapseBox {
    margin-top: 0px;
    .display {
      border: solid 1px #d8d8d8;
      border-top: none;
    }
  }
  .level-1 {
    .CollapseBox {
      .item {
        padding-left: 87px;
      }
      .display {
        .display-button {
          background-color: rgba(21, 37, 76, 0.1) !important;
          padding-left: 60px;
          color: #15254c;
          .toggle-icon {
            width: 24px;
            font-size: 20px;
            margin-right: 0px !important;
          }
        }
      }
    }
  }

  .level-2 {
    width: 100%;
    .CollapseBox {
      .item {
        padding-left: 113px;
      }
      .display {
        .display-button {
          padding-left: 87px;
          background-color: #fafafb !important;

          color: #373a3c;
          .toggle-icon {
            width: 24px;
            // font-size: 20px;
          }
          .toggle-text {
            // font-size: 20px;
          }
        }
      }
    }
  }

  .level-3 {
    width: 100%;
    .CollapseBox {
      .item {
        padding-left: 138px;
      }
      .display {
        .display-button {
          padding-left: 113px;
        }
      }
    }
  }

  .level-4 {
    width: 100%;
    .CollapseBox {
      .item {
        padding-left: 162px;
      }
      .display {
        .display-button {
          padding-left: 138px;
        }
      }
    }
  }

  .level-5 {
    width: 100%;
    .CollapseBox {
      .item {
        padding-left: 188px;
      }
      .display {
        .display-button {
          padding-left: 162px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .SectionListView {
    .CollapseList {
      .CollapseBox {
        .display {
          .display-button {
            min-height: 70px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .PageBreadcrumb {
    .breadcrumb {
      a.page-link {
        .link-text {
          max-width: 200px;
        }
      }
    }
  }

  .CollapseBox {
    .display {
      .display-button {
        padding: 10px;
        .toggle-icon {
          margin-right: 15px !important;
        }
      }
    }
  }
  .Document {
    .item {
      .file-name {
        margin-left: 15px;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 130px;
      }
      .group-download {
        width: 110px;
        padding-right: 10px;
      }
    }
    .level-1 {
      .CollapseBox {
        .item {
          padding-left: 30px;
        }
        .display {
          .display-button {
            padding-left: 20px;
          }
        }
      }
    }

    .level-2 {
      width: 100%;
      .CollapseBox {
        .item {
          padding-left: 40px;
        }
        .display {
          .display-button {
            padding-left: 30px;
          }
        }
      }
    }

    .level-3 {
      width: 100%;
      .CollapseBox {
        .item {
          padding-left: 50px;
        }
        .display {
          .display-button {
            padding-left: 40px;
          }
        }
      }
    }

    .level-4 {
      width: 100%;
      .CollapseBox {
        .item {
          padding-left: 60px;
        }
        .display {
          .display-button {
            padding-left: 50px;
          }
        }
      }
    }

    .level-5 {
      width: 100%;
      .CollapseBox {
        .item {
          padding-left: 70px;
        }
        .display {
          .display-button {
            padding-left: 60px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 541px) and (max-width: 768px) {
  .Document {
    .item {
      .file-name {
        margin-left: 15px;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
      }
      .group-download {
        width: 130px;
        padding-right: 20px;
      }
    }
    .level-1 {
      .CollapseBox {
        .item {
          padding-left: 50px;
        }
        .display {
          .display-button {
            padding-left: 30px;
          }
        }
      }
    }

    .level-2 {
      width: 100%;
      .CollapseBox {
        .item {
          padding-left: 70px;
        }
        .display {
          .display-button {
            padding-left: 50px;
          }
        }
      }
    }

    .level-3 {
      width: 100%;
      .CollapseBox {
        .item {
          padding-left: 90px;
        }
        .display {
          .display-button {
            padding-left: 70px;
          }
        }
      }
    }

    .level-4 {
      width: 100%;
      .CollapseBox {
        .item {
          padding-left: 110px;
        }
        .display {
          .display-button {
            padding-left: 90px;
          }
        }
      }
    }

    .level-5 {
      width: 100%;
      .CollapseBox {
        .item {
          padding-left: 130px;
        }
        .display {
          .display-button {
            padding-left: 110px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .BasicView {
    .main-content {
      &.row {
        flex-direction: column;
      }
      .thumbnail,
      .thumbnail-placeholder {
        flex-basis: unset;
        max-width: 480px;
        width: 100%;
        height: calc((100vw - 30px) * 0.66666667);
        min-height: unset;
        min-width: unset;
        max-height: 320px;
      }
      .body-content {
        padding-left: 0px;
        margin-top: 20px;
      }
    }
  }
}

.sc-bdVaJa {
  max-width: 100%;
}
.sc-bxivhb.bnwwju {
  display: none;
}

.MapView {
  width: 100%;
  position: relative;
  > div {
    max-width: 100%;
    max-height: 100%;
  }

  .zoom-section {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    .zoom-btn {
      margin: 5px;
      background: #fff;
      font-size: 14px;
      font-weight: bold;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: solid 1px #e5e5e5;
      border-radius: 2px;
      user-select: none;
      transition: all 0.2s;
    }
  }
}
