.PageBreadcrumb {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  margin: 22px 0px;
  align-items: center;
  .breadcrumb {
    a.page-link {
      font-family: 'HelveticaNeue', sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #15254c;
      .link-text {
        display: inline-block;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.active {
        color: #818a91;
      }
    }
  }
}
