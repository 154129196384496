.gm-style-iw.gm-style-iw-c {
  width: 300px;
  padding: 6px !important;
  min-height: 120px !important;
  max-height: unset !important;
  max-width: 270px !important;
  .gm-style-iw-d {
    max-width: 100% !important;
    overflow:  hidden !important;
  }
}

.info-window {
  height: 100%;
}
.thumbnail {
  width: 50%;
  height: 100%;
  float: left;
  // padding-right: 5px;
  // border-right: 1px solid #aaa;
  img {
    max-width: 100%;
  }
  .type-stage {
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
  }
}

.property-info{
  height: 100%;
  width: 50%;
  padding-left: 5px;
  float: right;
  .name {
    font-size: 14px;
    font-weight: bold;
    color: #375fac;
    span {
      font-weight: bold;
    }
  }
  .description {
    height: 100%;
    overflow: hidden;
    margin-top: 5px;
  }
}