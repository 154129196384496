.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  background: #fff;
  border: solid 1px #9a040f;
  color: #9a040f;
  padding: 10px;
  margin: 10px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background: #9a040f;
    color: #fff;
  }

  &.loading {
    opacity: 0.7;
    cursor: default;
    &:hover {
      background: #9a040f;
      color: #fff;
    }
  }

  &.disabled {
    background: #fff;
    border: solid 1px #bababa;
    color: #bababa;
    opacity: 0.7;
    cursor: default;
    pointer-events: none;
  }
  i {
    margin-right: 10px;
  }

  .btn-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    line-height: 1;
  }
}
