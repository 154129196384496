.content-note {
  display: flex;
  justify-content: center;
  background: #fafafb;
  border: solid 1px #d8d8d8;
  padding: 0px 10px;
  .content-show{
    width: 100%
  }
  .content-section {
    flex: 2 1 400px;
    padding-left: 15px;
    .content-text {
      border: 1px dashed #375fac;
      margin: 20px 0px;
      border-radius: 8px;
      padding: 0px 10px 5px 10px;
      background: #fff;
      .date {
        border-top: 1px solid #e2e0e0;
        text-align: right;
        font-size: 12px;
        color: #cccccc;
        line-height: 22px;
      }
    }
  }
  .content-edit{
    flex: 1 1 150px;
    max-width: 150px;
    display: flex;
    justify-content: center;
    .btn.btn-dark-blue {
      width: 100px;
      margin-top: 20px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 350px;

    .content-area {
      width: 90%;
      margin: 30px auto 15px auto;
      min-height: 200px;
      border: 1px solid #9594ca;
      border-radius: 2px;
      background: #fff;
      .content-header {
        margin-left: 2%;
        border-bottom: 2px solid #6666c4;
        width: 40px;
        line-height: 19px;
        font-weight: 600;
        height: 30px;
      }
      .content-holder {
        width: 96%;
        min-height: 150px;
        padding: 20px 20px 5px 20px;
        border-left: none;
        border-right: none;
        border-top-color: #e5e5e5;
        border-bottom-color: #e5e5e5;
        font-size: 14px;
        margin-left: 2%;
        resize: vertical;
        max-height: 400px;
        &:focus {
          outline: none;
        }
      }
      .content-error {
        font-size: 14px;
        font-style: italic;
        margin-left: 2%;
        color: red;
      }
      .content-toolbar {
        margin: auto 2%;
        padding: 10px 0px;
      }
    }
    .content-submit {
      width: 90%;
      margin: 5px auto 15px auto;
      display: flex;
      align-items: center;
      .button-container {
        float: left;
        margin-right: 10px;
        &.delete {
          margin-right: 20px;
        }
        .btn {
          border-radius: 5px;
          padding: 12px 19px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          margin: 4px 2px;
          -webkit-transition-duration: 0.4s; /* Safari */
          transition-duration: 0.4s;
          cursor: pointer;
        }
        .submit-button {
          margin-left: 0;
          width: auto;
          height: auto;
        }
        .btn-cancel {
          width: 100%;
          color: #fc9403;
          border: 1px solid #fc9403;
          &:hover {
            background-color: #fc9403;
            color: #fff;
          }
        }
        .btn-delete{
          background: none;
          border: none;
          padding: 4px 0;
          width: 30px;
          margin-left: 20px;
          color: #aaa;
        }
      }
    }
  }
  .attachment-button {
    display: inline-flex;
    font-size: 24px;
    transition: transform 0.1s;
    &[disabled] {
      pointer-events: none;
    }
    &:hover {
      transform: rotate(-20deg);
      -ms-transform: rotate(-20deg); /* IE 9 */
      -webkit-transform: rotate(-20deg);
      color: #0275d8;
    }
  }

  .attachment-file {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    .upload-wrapper{
      background: #fafafa;
      border: 1px solid #efefef;
      border-radius: 3px;
      flex: 1 1 270px;
      margin-bottom: 10px;
      margin-right: 20px;
      max-width: 270px;
      height: 42px;
      overflow: hidden;
      -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
      -moz-box-shadow: 0 1px 2px rgba(0,0,0,0.1);
      box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    }
    span {
      display: block;
      font-size: 12px;
      img, .other-file-name {
        width: 50px;
        margin-right: 20px;
        height: 42px;
        float: left;
      }
      .other-file-name i.fa {
        padding: 2px 0px 0px 8px;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 3px;
        display: block;
        width: 60%;
        float: left;
        height: 17px;
      }
    }
    button {
      background: none;
      border: none;
      margin-left: 12px;
      &:hover{
        cursor: pointer;
      }
      &:focus{
        outline: none;
      }
    }
  }
 

  .attachment-upload {
    font-size: 13px;
    margin: 10px 0px;
    display: flex;
    width: 50%;
    float: left;
    min-width: 300px;
    a {
      display: flex;
      align-items: center;
    }
    img, .other-file-name {
      width: 30px;
      height: 30px;
      float: left;
      overflow: hidden;
      .fa.fa-3x {
        padding: 0px;  
        font-size: 2em;
      }
    }

    span {
      padding: 0 10px;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span:first-of-type {
      width: 200px;
    }
  }
}

@media only screen and (max-width: 810px) {
    .content-note {
      flex-direction: column;
      .content-section {
        padding: 0px 15px;
      }
      .content-edit{
        max-width: 100%;
        max-height: 100px;
      }
      .attachment-upload{
        max-width: none;
        width: 100%;
        justify-content: center;
      }
      .Button.btn.btn-dark-blue{
        margin: 10px auto;
      }
    }
}
