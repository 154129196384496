.Property {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  position: relative;
  .navigate-buttons {
    padding-top: 40px;
    padding-bottom: 60px;
    max-width: 100%;
    .Button {
      margin: 0;
      &.next {
        margin-left: 10px;
      }
      &.finish {
        margin-left: 10px;
      }
    }
  }

  .header-section {
    .remove-property-button {
      display: inline-block;
      cursor: pointer;
      i {
        color: #be1e2d;
      }
    }
  }

  .RemoveProperty {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-left: 3px;
    .connector {
      margin-left: 10px;
      margin-right: 10px;
    }
    .remove-property-button {
      top: 2px;
      position: relative;
      cursor: pointer;
    }
  }
}
