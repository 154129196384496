.document-view-commponent {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  .btn-dark-blue {
    text-align: center;
    border: solid 1px #375fac;
    background: #ffffff;
    border-radius: 4px;
    font-weight: lighter;
    color: #375fac;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background 0.2s;
    &:hover {
      background: #375fac;
      color: #fff;
    }
  }

  .category-list {
    border: 1px solid #cccccc;
    border-radius: 4px;
    height: 655px;
    display: flex;
    .category-tree {
      width: 300px;
      background: #f3f3f3;
      padding: 20px;
      .add-category-btn {
        cursor: pointer;
        width: 100%;
        height: 38px;
        border-radius: 4px;
        font-family: 'HelveticaNeue', sans-serif;
        font-size: 16px;
        padding-top: 9px;
      }
      .scroll-wraper {
        overflow: auto;
        height: calc(100% - 38px);
        .category-items {
          .category-item-wraper {
            .category-item {
              display: flex;
              padding: 8px 5px 8px 8px;
              align-items: center;
              position: relative;
              &:hover {
                background: #9e9e9e21;
              }
              &.active {
                background: #d6d6d6;
              }
              .folder-icon {
                width: 17.5px;
                height: 17.5px;
                font-size: 24px;
                color: #16254c;
                line-height: 0.7;
                //background: url(../../../assets/images/folder-icon.svg) no-repeat;
              }

              .category-name {
                padding: 0 20px 0 10px;
                max-width: calc(100% - 70px);
                font-family: 'HelveticaNeue', sans-serif;
                font-size: 16px;
                line-height: 1.31;
                color: #16254c;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
                &.big {
                  max-width: calc(100% - 18px);
                }
              }

              .actions-wraper {
                background: none;
                position: absolute;
                right: 6px;
                top: 8px;
                display: none;
                &.is-mobile {
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                  .detail-icon-wraper {
                    width: 100%;
                    justify-content: flex-end;
                  }
                }
                &.small {
                  .edit-icon {
                    margin-right: 0;
                  }
                }
                .edit-icon {
                  cursor: pointer;
                  width: 21px;
                  height: 21px;
                  color: #16254c;
                  margin-right: 8px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 19px;
                }
                .add-icon {
                  cursor: pointer;
                  width: 21px;
                  height: 21px;
                  color: #16254c;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 26px;
                  padding-top: 1px;
                }
                .detail-icon-wraper {
                  color: #16254c;
                  width: 21px;
                  height: 21px;
                  font-size: 23px;
                  display: none;
                  margin-left: 0;
                  .detail-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  }
                }

                .delete-cat-icon-wraper {
                  cursor: pointer;
                  width: 21px;
                  height: 21px;
                  color: #16254c;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 24px;
                  margin-left: 8px;
                }
              }
              &.is-not-mobile:hover .actions-wraper {
                display: flex;
              }
              &.is-not-mobile {
                .category-name {
                  max-width: calc(100% - 18px);
                  padding: 0 0 0 10px;
                }
                .actions-wraper {
                  height: 100%;
                  top: 0;
                  right: 0;
                  justify-content: center;
                  align-items: center;
                  padding: 0 7px;
                }
              }
              &.is-mobile {
                .category-name {
                  padding: 0 10px;
                  max-width: calc(100% - 18px);
                }
              }
            }
            .subcategories-wraper {
              padding-left: 18px;
            }
          }
        }
      }
      &.is-mobile {
        width: 300px;
      }
    }
  }
  .popupbox-content {
    .save-category-btn-wraper {
      display: flex;
      justify-content: flex-end;
      .save-category-btn {
        cursor: pointer;
        width: 107px;
        height: 36px;
        font-family: 'HelveticaNeue', sans-serif;
        font-size: 14px;
        padding-top: 8px;
        text-align: center;
      }
    }
  }
}

.category-detail {
  .no-content-view {
    font-family: 'HelveticaNeue', sans-serif;
    text-align: center;
    font-size: 20px;
    padding-top: 41px;
    color: #9e9e9e;
  }
  &.view-mobile {
    display: none;
  }
  width: calc(100% - 300px);
  padding-left: 10px;
  .category-name {
    color: #16254c;
    font-family: 'HelveticaNeue', sans-serif;
    font-size: 24px;
    font-weight: 500;
    padding-right: 90px;
    margin: 14px 0 14px 0;
    padding-right: 90px;
    .close-popup-icon {
      display: none;
    }
    .add-new-files-icon {
      position: absolute;
      top: 11px;
      right: 11px;
      padding: 5px 8px;
      cursor: pointer;
      border-radius: 3px;
      font-family: 'HelveticaNeue', sans-serif;
      font-size: 14px;
      text-align: center;
    }
  }
  .category-file-list {
    height: calc(100% - 57px);
    .category-file-table {
      .item-row {
        width: 100%;
        height: 45px;
        display: flex;
        border-top: 1px solid #eceeef;
        &.tbl-header {
          border-bottom: 1px solid #eceeef;
          .cell {
            font-weight: bold;
            color: #16254c;
          }
        }
        &.first-row {
          border-top: none;
        }
        .cell {
          padding-left: 15px;
          padding-top: 11px;
          color: #373a3c;
          font-family: 'HelveticaNeue', sans-serif;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          flex: 1;
          &.file-name {
            -webkit-flex-grow: 2;
            flex-grow: 2;
          }
          &.actions {
            display: flex;
            max-width: 90px;
            .download-icon {
              color: #373a3c;
              font-size: 27px;
              margin-left: 5px;
              margin-top: -2px;
              line-height: 1;
            }
            .remove-icon {
              color: #373a3c;
              font-size: 28px;
              margin-top: -5.5px;
              cursor: pointer;
            }
          }
          .folder-icon {
            width: 17.5px;
            height: 17.5px;
            font-size: 24px;
            color: #373a3c;
            line-height: 0.9;
            // background: url(../../../assets/images/folder-icon.svg) no-repeat;
          }
          &.type-cell {
            max-width: 65px;
          }
          &.size-cell {
            max-width: 110px;
          }
          &.date-cell {
            max-width: 110px;
            min-width: 110px;
          }
        }
      }
    }
    .intrustion-text {
      background: rgba(0, 0, 0, 0.16);
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      padding: 50px;
    font-family: 'HelveticaNeue', sans-serif;
    font-weight: bold;
      color: #16254c;
      font-size: 22px;
      cursor: pointer;
      text-align: center;
      flex-direction: column;
      opacity: 0.6;
      .fa-plus-circle {
        margin-top: 30px;
        font-size: 150px;
        color: rgba(0, 0, 0, 0.3);
      }
    }
    .no-content-wraper {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      border-top: 1px solid #eceeef;
    }
  }
  &.is-mobile {
    width: calc(100% - 300px);
  }
}

.uploader-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  .overlay-dropzone {
    display: none;
    width: 100%;
    height: calc(100% - 123px);
    top: 123px;
    background: #2a2c2e80;
    justify-content: center;
    align-items: center;
    &.active {
      display: flex;
      position: absolute;
    }
    &.no-file {
      height: calc(100% - 78px);
      top: 78px;
    }
  }
}

@media only screen and (max-width: 942px) {
  .document-view-commponent {
    .category-list {
      .category-tree {
        width: 250px;
        padding: 12px;
        .scroll-wraper {
        }
      }
      .category-detail {
        .category-file-list .intrustion-text {
          height: 601px;
        }
        width: calc(100% - 250px);
        .category-name {
          padding-top: 10px;
          margin: 0 0 10px 0;
        }
        .uploader-container {
          .overlay-dropzone {
            height: calc(100% - 97px);
            top: 97px;
            &.no-file {
              height: calc(100% - 53px);
              top: 53px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 777px) {
  .document-view-commponent {
    .category-list {
      .category-tree {
        width: 100%;
        padding: 30px;
        .scroll-wraper {
          .category-items .category-item-wraper .category-item .actions-wraper {
            .detail-icon-wraper {
              display: flex;
            }
          }
        }
        &.is-mobile {
          padding: 10px;
          width: 100%;
        }
      }
      .category-detail {
        &.view-desktop {
          display: none;
        }
        &.view-mobile {
          display: flex;
        }
        padding: 10px;
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-top: -21px;
        .uploader-container {
          position: fixed;
          top: 10px;
          left: 10px;
          width: calc(100% - 20px);
          background: white;
          z-index: 999999999999;
          border-radius: 4px;
          height: calc(100vh - 31px);
          overflow: auto;
          padding: 0 5px;
          .overlay-dropzone {
            top: 93px;
            height: calc(100% - 93px);
            margin-left: -5px;
            &.no-file {
              height: calc(100% - 49px);
              top: 49px;
            }
          }
          .category-file-list {
            .intrustion-text {
              height: calc(100vh - 80px);
            }
            .category-file-table {
              .item-row {
                .cell {
                  padding-left: 5px;
                  font-size: 14px;
                  &.type-cell {
                    max-width: 45px;
                  }
                }
              }
            }
          }
        }
        .detail-category-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1002;
          background: none repeat scroll 0% 0% #000;
          opacity: 0.7;
        }
        .category-name {
          padding: 14px 105px 0 0;
          margin-bottom: 13px;
          font-size: 18px;
          .add-new-files-icon {
            display: block;
            position: absolute;
            top: 11px;
            right: 35px;
            padding: 5px 8px;
            cursor: pointer;
            border-radius: 3px;
            font-family: 'HelveticaNeue', sans-serif;
            font-size: 14px;
            text-align: center;
          }

          .close-popup-icon {
            display: block;
            position: absolute;
            top: 4px;
            right: 11px;
            font-size: 21px;
            cursor: pointer;
            color: #9e9e9e;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .document-view-commponent {
    .size-cell {
      display: none;
    }
  }
}
.swal2-container {
  z-index: 9999999999999999;
}
