.CommentView {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fafafb;
  flex-grow: 1;
  .section {
    border: solid 1px #d8d8d8;
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    .item-list {
      width: 100%;
      max-width: 574px;
    }
    .item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 16px;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .content {
        width: 100%;
        .comment-content {
          font-size: 14px;
        }
      }
      .profile {
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-name {
          margin-top: 3px;
          width: 61.5px;
          font-size: 10px;
          text-align: center;
          color: #262e40;
        }
      }

      .comment-input {
        flex: 1 1 0;
        position: relative;
        .content {
          width: 100%;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          line-height: 1.71;
          flex: 1 1 0;
          padding: 12px 18px 22px 14px;
          font-size: 14px;
          resize: vertical;
          min-height: 117px;
          max-height: 460px;
          position: relative;
        }
        .attachment-button {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          position: relative;
          z-index: 1;
          left: calc(100% - 33px);
          bottom: 33px;
          cursor: pointer;
          transition: transform 0.1s;
          color: #888;
          width: 25px;
          height: 25px;
          &[disabled] {
            pointer-events: none;
          }
          &:hover {
            transform: rotate(-20deg);
            color: #0275d8;
          }
        }
      }
      .date {
        z-index: 10;
        text-align: right;
        font-size: 12px;
        color: #cccccc;
        line-height: 22px;
      }
    }
    .view-more {
      margin-top: 20px;
      text-decoration: underline;
      text-align: center;
      color: #0275d8;
      cursor: pointer;
      font-size: 12px;
    }
  }

  .section.list {
    border-top: none;
    flex-direction: column;
    align-items: center;
  }

  .submit-button {
    margin-top: 12px;
    flex-grow: 1;
    display: flex;
    height: 38px;
    margin-left: 62px;
    border-radius: 4px;
    width: unset !important;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    cursor: pointer;
  }
  .loading {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;
  }

  .attachment-file {
    min-height: 14px;
    font-size: 10px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: -20px;
    div.upload-wrapper {
      width: 100%;
      &.image,
      &.video,
      &.pdf {
        width: auto;
      }
      &.video,
      &.pdf {
        &.upload-section {
          width: 100%;
        }
      }
      a {
        display: inline-block;
      }
    }
    .other-file-name {
      font-size: 12px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      .file-name {
        margin-left: 8px;
      }
    }
    .upload-file-thumbnail {
      width: 162px;
      height: 162px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      flex-direction: column;
      margin-right: 8px;
      margin-bottom: 8px;
      position: relative;
      border: solid 1px #e6e6e6;
      border-radius: 8px;
      object-fit: cover;
      .upload-file-name {
        display: none;
        text-align: center;
        font-size: 9px;
        position: absolute;
        z-index: 1;
        color: #fff;
        background: linear-gradient(
          rgba(90, 90, 90, 0.7),
          rgba(90, 90, 90, 0.7)
        );
        bottom: 0;
        left: 0;
        width: 100%;
        height: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 4px;
      }
    }
  }
}

@media screen and (max-width: 626px) {
  .CommentView {
    .section {
      padding: 10px 20px 30px 20px;
    }
  }
}
