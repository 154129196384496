.ForgotPassword {
  .sign-in-link {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    margin: 24px 0 8px;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .box-inputs {
    width: 100%;
    // .input-email{
    //   input{
    //     height: 30px;
    //     border-radius: 3px;
    //     background-color: #ffffff;
    //     border: solid 1px #262e40;
    //     font-family: 'Poppins', sans-serif;
    //     font-size: 14px;
    //     padding: 5px 14px;
    //     &:-webkit-autofill{
    //       background-color: #ffffff;
    //       -webkit-box-shadow: 0 0 0 30px white inset;
    //     }
    //   }
    // }
  }

}
