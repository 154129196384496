.interior-tour-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  padding: 10px 11px 0 11px;
  background: white;
  .asset-item[disabled] {
    opacity: 1;
  }
  .photo-item {
    height: 176px;
    width: 244px;
    display: flex;
    align-items: center;
    padding: 10px 11px 10px 11px;
    .photo-wraper {
      width: 100%;
      cursor: pointer;
      position: relative;
      .thumbnail {
        height: 130px;
        min-height: 130px;
        width: 100%;
        background-image: url('../../assets/images/asset_base_logo_with_text.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        border: solid 1px #ccc;
        position: relative;
        &.landscape {
          height: 101px;
          min-height: 101px;
        }
      }

      .spine {
        position: absolute;
        background-image: url('../../assets/images/binder_small.png');
        @media all and (-webkit-min-device-pixel-ratio: 1.5),
          all and (-o-min-device-pixel-ratio: 3/2),
          all and (min--moz-device-pixel-ratio: 1.5),
          all and (min-device-pixel-ratio: 1.5) {
          background-image: url('../../assets/images/binder_small@2x.png');
          background-size: 9px 11px;
        }
        background-repeat: repeat-y;
        height: 120px;
        z-index: 1;
        top: 5px;
        left: 0;
        width: 9px;
        &.landscape {
          background-image: url('../../assets/images/binder_small_rot.png');
          
            @media all and (-webkit-min-device-pixel-ratio: 1.5),
            all and (-o-min-device-pixel-ratio: 3/2),
            all and (min--moz-device-pixel-ratio: 1.5),
            all and (min-device-pixel-ratio: 1.5) {
              background-image: url('../../assets/images/binder_small_rot@2x.png');
              background-size: 9px 11px;
            }
          background-repeat: repeat-x;
          height: 10px;
          width: calc(100% - 8px);
          top: 0px;
          left: 4px;
          &:after {
            height: 3px;
            width: 5px;
            right: 0px;
            top: 0px;
            left: auto;
            background-image: url('../../assets/images/binder_rear_rot.png');
            
              @media all and (-webkit-min-device-pixel-ratio: 1.5),
              all and (-o-min-device-pixel-ratio: 3/2),
              all and (min--moz-device-pixel-ratio: 1.5),
              all and (min-device-pixel-ratio: 1.5) {
                background-image: url('../../assets/images/binder_rear_rot@2x.png');
                background-size: 11px 4px;
              }
          }
          &:before {
            top: 0px;
            height: 3px;
            width: 5px;
            left: 0;
            background-image: url('../../assets/images/binder_rear_rot.png');
            
              @media all and (-webkit-min-device-pixel-ratio: 1.5),
              all and (-o-min-device-pixel-ratio: 3/2),
              all and (min--moz-device-pixel-ratio: 1.5),
              all and (min-device-pixel-ratio: 1.5) {
                background-image: url('../../assets/images/binder_rear_rot@2x.png');
                background-size: 11px 4px;
              }
          }
        }
        &:before {
          content: '';
          position: absolute;
          background-image: url('../../assets/images/binder_rear.png');
          @media all and (-webkit-min-device-pixel-ratio: 1.5),
            all and (-o-min-device-pixel-ratio: 3/2),
            all and (min--moz-device-pixel-ratio: 1.5),
            all and (min-device-pixel-ratio: 1.5) {
            background-image: url('../../assets/images/binder_rear@2x.png');
            background-size: 4px 11px;
          }
          height: 4px;
          width: 4px;
          top: -2px;
          left: 0;
        }
        &:after {
          content: '';
          position: absolute;
          background-image: url('../../assets/images/binder_rear.png');
          @media all and (-webkit-min-device-pixel-ratio: 1.5),
            all and (-o-min-device-pixel-ratio: 3/2),
            all and (min--moz-device-pixel-ratio: 1.5),
            all and (min-device-pixel-ratio: 1.5) {
            background-image: url('../../assets/images/binder_rear@2x.png');
            background-size: 4px 11px;
          }
          height: 2px;
          width: 4px;
          bottom: -2px;
          left: 0;
        }
      }
      .pages {
        position: absolute;
        width: 3px;
        height: calc(100% - 4px);
        border-radius: 0 1px 1px 0;
        background-color: #fff;
        border: solid 0.5px #ccc;
        border-left: none;
        z-index: 0;
        top: 0;
        right: -2.5px;
        &.landscape {
          width: 2px;
          height: 129px;
          top: 36px;
          left: 64px;
          transform: rotate(90deg);
        }
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: calc(100% - 2px);
          background-color: #fff;
          border-radius: 0 1px 1px 0;
          border: solid 0.5px #ccc;
          border-left: none;
          z-index: -1;
          top: 0;
          right: -2px;
        }
      }
      .photo-overlay,
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: rgba(71, 71, 71, 0.6);
        font-size: 20px;
        .icon {
          width: 55px;
          height: 55px;
          background: url(../../assets/images/photo_360.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
        }
      }
      .label-wraper {
        width: 100%;
        font-family: 'HelveticaNeue', sans-serif;
        font-size: 14px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        padding-top: 7px;
      }
    }
  }
}

.fancybox-content {
  h3 {
    margin: 10px 0;
  }
  &.photo-fancybox-content {
    padding: 10px;
    background: none;
    .photo-content {
      height: 100%;
      max-height: 65vh;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden !important ;

      img {
        flex-shrink: 1;
        max-width: 100%;
        max-height: 65vh;
      }

      .sc-bdVaJa {
        max-width: 100%;
      }
      .sc-bxivhb.bnwwju {
        display: none;
      }
    }
  }

  .fancybox-close-small {
    color: #fff;
  }
  h3 {
    color: #fff;
    opacity: 0.7;
    text-align: center;
    width: 100%;
  }
}

.pano {
  max-height: 65vh;
  max-width: 80vw;
  .panolens-canvas {
    max-width: 100%;
    max-height: 100%;
  }
}

.fancybox-thumbs {
  top: auto;
  width: auto;
  bottom: 0;
  left: 0;
  right: 0;
  height: 95px;
  padding: 10px 10px 5px 10px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  ul {
    margin: 0 auto;
  }
}

.fancybox-show-thumbs .fancybox-inner {
  right: 0;
  bottom: 95px;
}

@media only screen and (max-width: 768px) {
  .fancybox-content {
    &.photo-fancybox-content {
      padding: 40px 0;
    }
  }
}

@media only screen and (max-height: 768px) {
  .fancybox-thumbs {
    height: 60px;
  }
  .fancybox-thumbs > ul > li {
    width: 70px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    bottom: 60px;
  }
}
