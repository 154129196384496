.CollapseBox {
  display: flex;
  flex-direction: column;
  .display {
    width: 100%;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .display-button {
      flex-grow: 1;
      min-height: 60px;
      background: #15254c;
      display: flex;
      color: #ffffff;
      flex-grow: 1;
      padding: 10px 20px;
      justify-content: flex-start;
      align-items: center;
      .toggle-icon {
        margin-right: 27.5px;
        font-size: 14px;
      }
      .toggle-text {
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: normal;
        user-select: none;
      }
    }
  }
  .box-container {
    flex-grow: 1;
    min-height: 45px;
    &.show {
      display: block;
      padding-bottom: 20px;
    }

    &.hide {
      display: none;
    }
  }
}
