.PropertyReport {
  display: flex;
  flex-direction: column;

  .top-buttons {
    display: flex;
    align-items: center;
  }
}

.btn {
  &.btn-export {
    margin-left: auto;
    margin-right: 0;
  }
}

.ReactTable .rt-tbody .rt-td {
  &:empty {
    &:after {
      content: '-';
    }
  }
  &:last-child {
    &:empty {
      &:after {
        content: '';
      }
    }
  }
}

.SearchBox {
  display: flex;
  align-items: center;
  position: relative;
  input {
    border-radius: 999px;
    padding: 5px 12px;
    padding-left: 30px;
    font-size: 14px;
    border: solid 1px #ccc;
    width: 200px;
    height: 30px;
    &:focus,
    &:hover,
    &:active,
    &:visited {
      box-shadow: none;
      outline: none;
    }
  }
  .fa.fa-search {
    position: absolute;
    left: 10px;
    align-self: center;
    color: #ccc;
  }
}

@media screen and (max-width: 425px) {
  .PropertyReport .top-buttons {
    flex-direction: column;
  }
  .SearchBox {
    width: 100%;
    input {
      width: 100%;
    }
  }
}
