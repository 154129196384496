.CustomerProfile {
  .DirectUploadSingleImage {
    .text-placeholder {
      display: none;
    }
    &.placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: 1px solid #cccccc;

      label.preview {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/avatar.svg');
        background-size: contain;
        opacity: 1;
      }
    }
  }
  .customer-profile-form {
    max-width: 1024px;
    .user-info {
      padding-left: 12px;
      width: calc(100% - 250px);
      .txt-first-name {
        width: 49%;
      }
    }
    .avatar {
      &.placeholder {
        label.preview {
          display: block;
          background-color: #eee;
          background-image: url('http://via.placeholder.com/200x200');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
    }

    label {
      font-size: 18px;
    }
    .w-32-percent {
      .css-10nd86i {
        margin-top: 6px;
      }
      width: 32%;
    }
  }
  .profile-country {
    .select__control {
      cursor: pointer;
      height: 45px;
      margin-top: 5px;
      box-shadow: none;
      &.select__control-is-focused {
        border-color: #262e40 !important;
      }
    }
  }
}

.UserProfilePage {
  flex: 1;
  padding-top: 45px;
  .customer-profile-form {
    max-width: 1024px;
    width: 100%;
  }
}

@media screen and (max-width: 680px) {
  .CustomerProfile {
    .customer-profile-form {
      .profile-info {
        .flex {
          .avatar {
            display: flex;
            justify-content: center;
          }
          &.row {
            flex-direction: column;
          }
          &.user-info {
            padding-left: 0px;
            margin-top: 20px;
            width: 100%;
            .txt-first-name {
              width: 100%;
            }
          }
          .css-10nd86i {
            margin-bottom: 10px;
          }
        }
        .w-32-percent {
          width: 100%;
        }
      }
    }
  }
}
