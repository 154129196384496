.PaymentHistory {
  padding-top: 45px;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 680px) {
    max-width: 100%;
    
  }
  .header-section {
    padding: 20px 0px;
    .title {
      font-size: 23px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      margin-bottom: 20px;
      display: inline-block;
      text-transform: uppercase;
    }
  }
  .invoices-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px auto;

    .invoice-card {
      width: 600px;
      margin-bottom: 15px;
      border: solid 1px;
      @media screen and (max-width: 680px) {
        width: 100%;
        
      }
      .invoice-header {
        display: flex;
        justify-content: flex-end;
        height: 40px;
        padding: 12px;
        border-bottom: solid 1px;

        .invoice-date {
          display: inline-block;
        }
      }
      .invoice-content {
        margin-bottom: 24px;
        padding: 12px;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        .invoice-name {
          width: 70%;
          height: 100%;
          p {
            font-size: 14px;
          }
          .invoice-type {
            font-size: 20px;
          }
        }
        .invoice-amount {
          width: 30%;
          height: 100%;
          p {
              font-size: 14px;
            }
          .amount {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.PaymentHistoryPage {
  flex: 1;
  width: 100%;
}

.PaymentHistoryPage {
  .popupbox-wrapper {
      min-width: 50%;
  }
     .ListPropertyHeader {
       display: flex;
       flex-direction: row;
       justify-content: flex-start;
       align-items: center;
       margin-bottom: 20px;
  
       .header-item {
         text-align: center;
         min-width: 100px;
         font-weight: bold;
         padding: 12px 15px;
         cursor: pointer;
  
         &.active {
           color: #BE1E2D;
           border-bottom: solid 2px #BE1E2D;
         }
       }
     }
}
