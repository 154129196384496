$primary-color: #262e40;
$secondary-color: #e5e5e5;
$input-color: #373a3c;

.Input {
  font-size: 24px;
  margin-bottom: 10px;
  position: relative;
  label {
    font-size: 24px;
  }

  .form-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
  }
  input {
    width: 100%;
    height: 46px;
    margin-top: 5px;
    padding: 8px 12px 8px 12px;
    font-size: 21px;
    font-weight: 400;
    background: #fff;
    color: $input-color;
    border: solid 1px $secondary-color;
    border-radius: 3px;

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: $secondary-color;
    }

    &:focus {
      border: solid 1px $primary-color;
      outline: none;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        color: $primary-color;
      }
    }
  }

  &.disabled {
    color: gray !important;
    input {
      color: gray !important;
      border: solid 1px $secondary-color;

      &:focus {
        border: solid 1px $secondary-color;
        color: #373a3c;
        outline: none;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
          color: $secondary-color;
        }
      }
    }
  }

  &.err {
    input {
      box-shadow: inset 0px 0px 2px 1px rgba(199, 4, 4, 0.5);
      border: solid 1px #cb0000;
    }
  }
  span.err-message {
    background: #bb000e;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    padding: 5px;
    display: block;
    border-radius: 0px 0px 3px 3px;
    margin-top: -2px;
  }
}
