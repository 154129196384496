
.Cookies {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 10px;
  width: 100%;
  max-width: 1140px;
  flex-grow: 1;
  * {
      max-width: 100%;
  }
}

.CookiesPage {
  flex: 1;
  width: 100%;
  max-width: 1140px;

}