.ListProperty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  .options {
    width: 100%;
    display: flex;
    flex-direction: row;
    .btn.btn-dark-blue {
      margin: 0;
      margin-right: 10px;
    }
  }

  .list-property {
    width: 100%;
    min-height: 500px;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 32px;
    .Item {
      padding-bottom: 30px;
      margin-right: 40px;

      &:nth-child(3n) {
        margin-right: 0px;
      }
    }
  }

  .PropertyItem {
    width: 320px;
    background: #fff;
    box-shadow: 0 0 20px #ddd;
    border: solid 1px #eee;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    transition: box-shadow 0.3s;
    position: relative;
    z-index: 0;

    .new-label {
      position: absolute;
      top: 10px;
      right: 2px;
      z-index: 2;
    }

    &:hover {
      box-shadow: 4px 4px 8px 0px rgba(108, 126, 142, 0.2);
      transition: box-shadow 0.3s;
    }
    .thumbnail {
      height: 180px;
      min-height: 180px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
    }
    .thumbnail-placeholder {
      height: 180px;
      min-height: 180px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .image {
        display: block;
        width: 144px;
        height: 108px;
        opacity: 0.7;
        background: url('../../assets/images/asset_base_logo_with_text.svg') no-repeat;
        background-size: contain;
      }
    }
    .body-content {
      padding: 20px 20px 20px 20px;
      border-top: solid 1px #16254c;
      color: #16254c;
      .name {
        flex-basis: 100%;
        font-size: 18px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .info {
        font-size: 14px;
        margin-top: 14px;
        .row {
          display: flex;
          flex-direction: row;
          width: 100%;
          line-height: 1.71;
          label {
            font-weight: 600;
            min-width: 100px;
          }

          .content {
            flex-basis: 100%;
            flex-grow: 1;
            font-weight: 200;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.btn.btn-dark-blue {
  width: 160px;
  height: 38px;
  border: solid 1px #375fac;
  background: #ffffff;
  border-radius: 4px;
  font-size: 16px;
  line-height: 21px;
  font-weight: lighter;
  color: #375fac;
  user-select: none;
  &:hover {
    background: #375fac;
    color: #fff;
  }
}

.touchevents {
  .btn.btn-dark-blue {
    width: 160px;
    height: 38px;
    border: solid 1px #375fac;
    background: #ffffff;
    border-radius: 4px;
    font-size: 16px;
    line-height: 21px;
    font-weight: lighter;
    color: #375fac;
    user-select: none;
    &:hover {
      color: #375fac;
      background: #fff;
    }
  }
}

.filters {
  position: relative;
  z-index: 0;
  min-width: 700px;
  .filer-main-content {
    .Input {
      input {
        &:focus {
          border: solid 1px #375fac;
        }
      }
    }
  }
  .flex.row {
    label,
    .label {
      flex-basis: 15%;
      font-size: 14px;
      font-weight: bolder;
    }

    .input-container {
      flex-basis: 85%;
      margin-bottom: 0px;
    }
  }
}

.basic-multi-select {
  .select__control {
    min-height: 46px;
    margin-top: 5px;
  }
  .select__indicator-separator {
    display: none;
  }
  .select__dropdown-indicator {
    display: none;
  }
}

.btn.sort-by-button {
  margin: 0;
  margin-top: 10px;
  padding: 5px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  .btn-container {
    justify-content: space-between;
  }
  i {
    margin-left: 4px;
    margin-right: 0px;
  }

  .sort-text {
    text-align: center;
  }
  .sort-icon {
    display: flex;
    width: 35px;
    justify-content: center;
    align-items: center;
    padding-right: 2px;
  }
}

.sort-option {
  color: #373a3c;
  cursor: pointer;
  user-select: none;
  i.fa {
    visibility: hidden;
    margin-left: 10px;
  }
  &.selected {
    i.fa {
      visibility: visible;
    }
  }
}
@media screen and (max-width: 600px) and (min-width: 480px) {
  .ListProperty {
    .PropertyItem {
      .thumbnail {
        height: 280px;
        min-height: 280px;
      }
      .thumbnail-placeholder {
        height: 280px;
        min-height: 280px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ListProperty {
    .list-property {
      justify-content: space-between;
      .Item {
        margin: 0;
        width: 48%;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 1000px) {
  .ListProperty {
    .list-property {
      justify-content: flex-start;
      .Item:nth-child(2n) {
        margin-right: 20px;
      }
      .Item:nth-child(3n) {
        margin-right: 0px;
      }
      .Item:nth-child(3n + 2) {
        margin-right: 20px;
      }
      .Item {
        margin-right: 20px;
        padding-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1040px) {
  .DashboardPage {
    .ListProperty {
      .popupbox {
        .popupbox-wrapper {
          width: 100%;
          .popupbox-content {
            .filters {
              min-width: unset;
              width: 100%;
              .filer-main-content {
                .flex {
                  &.row {
                    flex-direction: column;
                    margin-top: 10px;
                  }
                  &.al-center {
                    align-items: unset;
                  }
                }
              }
              .filter-footer {
                .btn-dark-blue {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  .DefaultLayout {
    .PageContent {
      margin-bottom: 35px;
    }
    .scroll-top-button {
      &.show {
        right: unset !important;
        bottom: 44px;
      }
      &.hide {
        right: unset !important;
        bottom: -50px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .DashboardPage {
    .ListProperty {
      .options {
        flex-direction: column;
        margin-top: 10px;
        .dd-menu {
          &.dd-menu-left {
            margin-top: 20px;
          }
        }
        .popupbox {
          .popupbox-wrapper {
            width: 100%;
            .popupbox-content {
              .filters {
                .filter-footer {
                  .btn-dark-blue {
                    margin-right: 0px;
                  }
                }
              }
            }
          }
        }
        .btn {
          width: 100%;
        }
      }
    }
  }
  .ListProperty {
    .list-property {
      .Item {
        margin-right: 0px;
        width: 100%;
      }
    }
  }
}

span.has-filters-indicator {
  background: #a92633;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.new-label {
  padding: 5px 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  background: #2a4489;
  border-radius: 4px;
  //box-shadow: 0px 0px 2px 1px #b4b4b4;
  display: inline;
  vertical-align: middle;
  margin-right: 10px;
}

.Favorite {
  cursor: pointer;
  position: absolute;
  margin: 5px;
  //padding: 5px;
  right: 0;
  bottom: 0;
  color: #f03b50;
  color: #e5e5e5;

  span.mdi {
    font-size: 25px;
    vertical-align: bottom;
  }
  span.fa {
    font-size: 25px;
    vertical-align: bottom;
  }

  transition: all 0.2s;

  &:hover {
    span.mdi {
      text-shadow: 0 0 5px;
    }
  }

  &.is-loading {
    pointer-events: none;
  }
}


.ListPropertyContainer {
  .ListPropertyHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    .header-item {
      text-align: center;
      min-width: 100px;
      font-weight: bold;
      padding: 12px 15px;
      cursor: pointer;
      &.active {
        color: #BE1E2D;
        border-bottom: solid 2px #BE1E2D;
      }
    }
  }
}
.owner-btn {
  border: none;
  background: #fff;
  color: #262E40;
  position: absolute;
  font-size: 24px;
  bottom: 10px;
  right: 2px;
  z-index: 1;
  padding: 5px;
  cursor: pointer;
}