@import "variables";

.SignUpLayout {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  background: white;
  color: #000;
  width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  position: relative;
  font-family: 'Poppins', sans-serif;
  padding-top: 101px;
  .SignUpPageHeader {
    background: #fff;
    display: block;
    text-align: center;
    width: 100%;
    flex-shrink: 0;
    border-bottom: solid 1px #bf1e2e;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .HeaderContent {
      width: 100%;
      max-width: 1140px;
      padding: 10px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .HeaderRight {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      a {
        margin-left: 10px;
       
      }
    }

    .HeaderLeft {
      >a {
        display: inline-block;
        line-height: 1;
        width: 75px;
        height: 75px;
      }
    }
    .header-logo {
      display: inline-block;
      width: 75px;
      height: 75px;
      cursor: pointer;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .SignUpPageFooter {
    background: white;
    display: block;
    text-align: center;
    width: 100%;
    flex-shrink: 0;

    .FooterContent {
      width: 100%;
      max-width: 1140px;
      min-height: 200px;
      padding: 30px 10px;
      display: inline-flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .FooterRight {
      display: inline-flex;
      flex-direction: row;
      align-items: start;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        
      }

      a {
        margin-left: 10px;
      }
    }

    .FooterLeft {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .left-middle-content {
        max-width: 360px;
        margin-bottom: 26px;
      }
    }

    .footer-logo {
      display: inline-block;
      width: 75px;
      height: 75px;
      cursor: pointer;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .footer-text {
      font-size: 12px;
      margin-top: auto;
    }

    ul.FooterLinks {
      list-style: none;
      text-align: left;
      font-size: 16px;
      margin: 0;
      padding: 0;
      margin-left: 30px;
      min-width: 300px;
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
      li {
        line-height: 1.5;
        margin-left: 0;
        padding: 6px 0px;
        a {
          margin-left: 0;
        }
      }
      .static {
        cursor: normal;
        font-size: 20px;
        margin-bottom: 10px;
        border-bottom: solid 2px;
      }

      .highlight {
        font-weight: bold;
        color: $theme-color;
      }
    }
  }

  .PageContent {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

 
  @media screen and (max-width: 768px) {
    .Input input {
        font-size: 16px;
      }

        div.select__single-value,
        div.select__multi-value__label,
        div.select__placeholder {
          font-size: 16px;
        }
    
  }
}

.ThemeButton {
  background-color: $theme-color;
  color: white;
  border: solid 1px $theme-color;
  cursor: pointer;
  min-width: 140px;
  padding: 12px 14px;
  border-radius: 8px;
  text-align: center;
  font-family: 'Poppins', sans-serif;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:hover {
    background-color: darken($color: $theme-color, $amount: 10);
    border: solid 1px darken($color: $theme-color, $amount: 10);
    color: #fff;
  }

    &.btn-log-in {
      padding-left: 40px;
      padding-right: 40px;
    }
}

.GhostButton {
  color: $theme-color;
  background-color: white;
  border: solid 2px lighten($color: $theme-color, $amount: 40);
  cursor: pointer;
  min-width: 140px;
  padding: 12px 14px;
  border-radius: 8px;
  text-align: center;

  &:hover {
    background-color: $theme-color;
    border: solid 2px $theme-color;
    color: #fff;
  }
}


.text-left {
  text-align: left;
}