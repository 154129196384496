.SignUpBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 40px;
    background-color: #f1f1f1;
    max-width: 600px;
    width: 100%;
    .SignUpBoxHeader {
      width: 100%;
      text-align: center;
      padding: 20px 40px;
      @media screen and (max-width: 768px) {
      h2 {
          font-size: 20px;
        }
      }
      
      p {
        line-height: 1.25;
        a {
          color: #414bb2;
        }
      }
    }

  .box-inputs {
    width: 100%;

    .input-row {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      > *:not(:last-child) {
        margin-right: 10px;
      }
      .input-phone-number {
        width: 100%;
      }
      .mobileCode {
        min-width: 100px;
        margin-top: 5px;
        .select__control {
          height: 46px;
        }
      }
    }
  }

  .error-container {
    width: 100%;
    max-width: 318px;
    overflow: hidden;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 20px;
  }
}

button[type="submit"].ThemeButton {
  font-size: 16px;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.SignUpPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 40px;
}

.medium-logo {
  width: 140px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.text-right {
  text-align: right;
}

.underline {
  text-decoration: underline;
}

.subscription-plans-link {
  text-decoration: underline !important;
  line-height: 1.5;
  text-underline-offset: 6px;
}

.w-full {
  width: 100%;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.text-center {
  text-align: center;
}
.inline-block {
  display: inline-block;
}