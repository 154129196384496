.basic-information {
  flex-basis: auto;
  flex-grow: 1;
  padding: 37px 0px;
  &.view-only {
    padding: 0;
  }
  .basic-container {
    flex-basis: auto;
    flex-grow: 1;
    position: relative;

    .loading-overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      pointer-events: none;
      color: #fff;
      i.fa {
        font-size: 250px;
      }
    }
  }
  .basic-information-form {
    min-height: 200px;
    label {
      font-size: 21px;
      input {
        height: 36px;
      }
    }
    .input-container {
      &.disabled {
        color: gray !important;
      }
      &.select {
        .select__--is-disabled {
          pointer-events: visible;
          cursor: not-allowed;
          color: gray !important;
        }
        .select__control {
          cursor: pointer;
          height: 46px;
          margin-top: 5px;
          margin-bottom: 10px;
          box-shadow: none;
          border-radius: 0;
          border-color: #e5e5e5;

          &.select__control--is-focused {
            border-color: #262e40 !important;
          }
          &.select__control--is-disabled {
            border-color: #e5e5e5;
            pointer-events: none;
          }
        }
      }
      .select__value-container--has-value {
        .select__single-value--is-disabled {
          color: gray !important;
        }
      }
      .css-10nd86i {
        margin-top: 9px;
      }
    }
    .Input {
      abbr {
        color: #be1e2d;
      }
    }
    input,
    textarea {
      border-radius: 0;
    }
    .property-details-container {
      width: 100%;
      textarea.property-details {
        margin-top: 5px;
        margin-bottom: 10px;
        padding: 8px 10px;
        width: 100%;
        min-height: 120px;
        font-size: 14px;
        resize: none;
        border: solid 1px #ccc;
        &:focus {
          outline: none;
          border: solid 1px #262e40;
        }
      }
    }
  }
  .hz-line {
    display: block;
    width: 100%;
    height: 2px;
    background: #444;
    margin: 20px 0;
  }
  .access-permissions {
  }
  .thumbnail {
    height: 268px;
    min-width: 390px;
    padding-top: 8px;
    margin-right: 20px;
  }
}

.UserAccessList {
  padding-top: 30px;
  .access-list-container {
    &.loading {
      opacity: 0.5;
      user-select: none;
      pointer-events: none;
    }
    .list-header,
    .user-access {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      padding-right: 10%;
      margin: 10px 0px;
      .who-has-access {
        flex-basis: 60%;
        font-size: 21px;
      }
    }

    .list-content {
      padding-bottom: 10px;
      .user-access {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        padding-right: 10%;
        margin: 10px 0px;
        .who-has-access {
          flex-basis: 60%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 62px;
          .avatar {
            flex-basis: 62px;
            width: 62px;
            height: 62px;
            background-color: #d8d8d8;
            background-image: url('../../../assets/images/avatar.svg');
            background-size: cover;
            background-position: center;
            border-radius: 50%;
          }
          .name {
            width: 120px;
            height: 30px;
            width: 100%;
            padding: 0 30px;
            display: flex;
            align-items: center;
          }
          .email {
            width: 120px;
            height: 30px;
            width: 100%;
            padding: 0 30px;
            display: flex;
            align-items: center;
          }
        }

        .remove {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-basis: 150px;
          .remove-button {
            cursor: pointer;
          }
        }
      }
    }
  }
  .add-user-button-container {
    width: 180px;
    .btn-dark-blue {
      margin-left: 0px;
      .fa {
        font-size: 20px;
      }
    }
  }
}

.basic-information.view-only {
  .UserAccessList {
    padding: 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .InviteUserBox {
    .box-container {
      .emails-section {
        .createable-select__placeholder {
          top: 18px !important;
        }
      }
    }
  }
  .fancybox-navigation .fancybox-button--arrow_left {
    left: 30px;
  }
  .fancybox-navigation .fancybox-button--arrow_right {
    right: 30px;
  }
}

.InviteUserBox {
  width: 100%;
  min-width: 600px;
  .box-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    .emails-section {
      flex-basis: 100%;
      flex-grow: 1;
      .createable-select__control {
        cursor: text;
      }
      label {
        display: block;
        margin-bottom: 10px;
      }
      .createable-select__input {
        input {
          max-width: calc(100vw - 140px);
        }
      }
      .createable-select__placeholder {
        top: 15px;
      }
      .err-message {
        margin-top: 10px;
      }
    }

    .permission-section {
      width: 260px;
      flex-basis: 260px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      .CheckBox {
        margin-left: 10px;
      }
      .checkbox-label {
        display: none;
      }
      .can-edit,
      .can-view {
				text-align: right;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          cursor: default;
          margin-bottom: 10px;
        }
        .checkbox-box {
          cursor: pointer;
          margin: 0;
        }
      }
    }
  }

  .footer {
    padding-top: 20px;
  }
}

.PermissionOption {
  width: 200px;
  flex-basis: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  .checkbox-label {
    display: none;
  }
  .res-can-edit,
  .res-can-view {
    display: none;
  }
  .can-edit,
  .can-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      margin-bottom: 10px;
    }
    .CheckBox {
      .checkbox-box {
        margin: 0;
        cursor: pointer;
      }
      &.disabled {
        .checkbox-box {
          cursor: not-allowed;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .UserAccessList {
    .access-list-container {
      .list-content {
        .user-access {
          .remove {
            padding-right: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .UserAccessList {
    .access-list-container {
      .list-content {
        .user-access {
          flex-direction: column;
          border: 1px solid #cccccc;
          border-radius: 5px;
          margin-top: 5px;
          padding: 5px !important;
          .remove {
            flex-basis: 10%;
            order: 1;
            justify-content: flex-end;
            margin-bottom: -15px;
            padding-right: 0px;
            z-index: 1;
          }
          .who-has-access {
            order: 2;
          }
          .PermissionOption {
            order: 3;
            margin-top: 10px;
            flex-basis: unset;
            width: unset;
            justify-content: space-between;
            .res-can-edit {
              display: flex;
            }
            .res-can-view {
              display: flex;
            }
          }
        }
      }
      .list-header {
        .PermissionOption {
          .can-edit {
            display: none;
          }
          .can-view {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .InviteUserBox {
    min-width: unset;
    .footer {
      align-items: center;
    }
    .box-container {
      flex-direction: column;
      .emails-section {
        margin-right: 0px;
        max-width: 100%;
      }
      .permission-section {
        margin-top: 30px;
        flex-basis: unset;
        width: unset;
        justify-content: space-between;
        .can-edit,
        .can-view {
          flex-direction: row;
          flex-basis: 45%;
          justify-content: space-between;
          span {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .basic-information {
    .UserAccessList {
      .add-user-button-container {
        width: 100%;
        .Button {
          width: 100%;
          margin-right: 0px;
        }
      }
    }
  }
  .Property {
    .footer-section {
      .navigate-buttons {
        margin-top: 20px;
        .Button {
          width: 100%;
          margin-left: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Property {
    .basic-information {
      .thumbnail {
        margin-bottom: 20px;
        margin-right: 0px;
      }
      .flex {
        &.row {
          flex-direction: column;
        }
      }
      .footer-section {
        .navigate-buttons {
          padding-top: 0px !important;
          padding-bottom: 20px !important;
          align-items: flex-end;
          flex-direction: row;
        }
      }

      .UserAccessList {
        .access-list-container {
          .list-header {
            padding: unset;
            .who-has-access {
              flex-basis: 85%;
            }
          }
          .list-content {
            .user-access {
              padding: unset;
              .who-has-access {
                flex-basis: 85%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .Property {
    .footer-section {
      .navigate-buttons {
        justify-content: space-between !important;
        flex-direction: column;
        .Button {
          margin: 5px 0 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .basic-information .thumbnail {
    width: 100%;
    min-width: unset;
    height: calc((100vw - 40px) * 0.66666667);
    .DirectUploadSingleImage {
    }
  }

  .Property {
    .footer-section {
      .navigate-buttons {
        justify-content: space-between !important;
        flex-direction: column;
        .Button {
          margin: 5px 0 !important;
          width: 100%;
        }
      }
    }
  }

  .Property {
    .basic-information {
      .footer-section {
        .navigate-buttons {
          justify-content: space-between !important;
          flex-direction: column;
        }
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .Property {
    .basic-information {
      .basic-container {
        .thumbnail {
          .DirectUploadSingleImage {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .Property {
    .basic-information {
      .basic-container {
        .thumbnail {
          height: calc((100vw - 30px) * 0.66666667);
          .DirectUploadSingleImage {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .Property {
    .basic-information {
      .basic-container {
        .thumbnail {
          .DirectUploadSingleImage {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
      }
    }
  }
}
