.PropertyPageHeader {
  padding-top: 8px;
  .breadcrumb {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    div.menu-item {
      display: inline-block;
      span.connector {
        margin-left: 8px;
        margin-right: 8px;
        font-weight: normal !important;
      }
      user-select: none;
      cursor: pointer;
      &.active {
        font-weight: 700;
      }
      &.active,
      &.disabled {
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .PropertyPageHeader {
    .breadcrumb {
      div.menu-item {
        display: flex;
        line-height: 24px;
        span.connector {
          display: none;
        }
      }
      .RemoveProperty{
        margin-left: -3px;
      }
    }
  }
  .Property .RemoveProperty .connector {
    display: none;
  }
}
