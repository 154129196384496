.DashboardPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: auto;
  width: 100%;

  .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 0;
    flex-basis: 100%;
    .title {
      font-size: 24px;
    }
  }
  .ListProperty {
    // flex-basis: 100%;
    padding-top: 20px;
  }
}
