@import 'variables';

.LoginLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background: $background-color url('../images/login_bg.png') no-repeat;
  background: url('../images/login_bg_2.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  min-height: 640px;
}
