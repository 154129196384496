/*  all stypes here */
@import 'variables';

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: #16254c;
  font-family: 'HelveticaNeue', sans-serif;
  width: 100vw;
  height: 100vh;
}

button {
  font-family: inherit;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.err-message {
  width: 100%;
  color: #be1e2d;
  a {
    color: #be1e2d !important;
  }
}

.noscroll {
  overflow-y: hidden !important;
}
.popupbox {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 20px 10px;
  display: none;
  align-items: baseline;
  &.is-active {
    display: flex;
  }

  .popupbox-wrapper {
    margin: auto 0;
    overflow: visible;
    .popupbox-content {
      overflow: visible;
    }
  }
}

.popupbox-titleBar {
  text-shadow: none;
}

a {
  text-decoration: none;
  color: #16254c;
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &:visited,
  &:focus {
    outline: none !important;
  }
  &.external-link {
    text-decoration: underline;
  }
}

a[disabled] {
  pointer-events: none;
  opacity: 0.4;
}

/* flex box styles */

.flex {
  display: flex;
  &.row {
    flex-direction: row;
  }
  &.column {
    flex-direction: column;
  }
  &.grow-1 {
    flex-grow: 1;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.jc-end {
    justify-content: flex-end;
  }

  &.jc-start {
    justify-content: flex-start;
  }
  &.jc-center {
    justify-content: center;
  }
  &.al-center {
    align-items: center;
  }
}

.btn {
  position: relative;
  padding: 8px 10px;
  font-size: 18px;
  background: #fff;
  color: #333;
  border-radius: 3px;
  margin: 10px;
  border: solid 1px black;
  cursor: pointer;
  line-height: 1;
  &:visited,
  &:active {
    outline: none;
  }
  transition: background 0.2s;
  &:hover {
    background: #afafaf;
    transition: background 0.2s;
  }
}
.btn-login {
  background-color: #375fac;
  color: #fff;
  height: 30px;
  border-radius: 3px;
  background-color: #375fac;
  border: none;
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: 'HelveticaNeue', sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 8px;
  cursor: pointer;
  margin: 5px 0 2px;
  &:hover {
    background: #1c3f89;
  }

  &.loading {
    cursor: default;
    background: #1c3f89;
    &:hover {
      background: #1c3f89;
    }
  }
}
.invisible {
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.rubify-react-file-upload-container {
  width: 180px;
  height: 180px;
  .rubify-react-avatar-upload-preview {
    width: 100%;
    height: 100%;
    span {
      width: 100%;
      height: 100%;
    }
  }
}

.rubify-react-form {
  .form-group {
    margin-top: 10px;
    &:first-child {
      margin-top: 0px;
    }
    label.control.label {
      font-size: 1.2em;
    }
    input {
      margin-top: 5px;
      width: 100%;
      height: 40px;
      font-size: 1.2em;
      text-indent: 10px;
    }

    abbr {
      color: #be1e2d;
    }
  }
}

div.rubify-react-combo-box-container {
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
  background: #fff;

  position: relative;
  &:focus {
    outline: none;
    a.rubify-react-combo-box-single {
      border: 1px solid #333 !important;
    }
  }
  a.rubify-react-combo-box-single {
    width: 100%;
    display: block;
    padding: 8px 12px;
    color: inherit;
    font-size: 18px;
    border: 1px solid #ababab;
    position: relative;
    margin-top: 5px;
    div.rubify-react-combo-box-down-arrow {
      position: absolute;
      right: 10px;
      top: 16px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 7px solid #999;
    }
  }
  div.rubify-react-combo-box-drop-down {
    background: #fff;
    overflow-y: hidden;
    position: absolute;
    width: 100%;
    border: 1px solid #ababab;
    border-top: 0;
    padding: 5px 6px;
    z-index: 50;
    div.rubify-react-combo-box-search input {
      width: 100%;
      border: 1px solid #ababab;
      padding: 6px 6px;
      outline: none;
    }
    .rubify-react-combo-box-results {
      padding-left: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      padding-top: 5px;
      max-height: 160px;
      overflow-y: auto;
      list-style-type: none;
      li {
        cursor: pointer;
        padding: 6px 6px;
        font-size: 14px;
        &.active {
          background: #316bc9;
          color: #fff;
        }
        em {
          text-decoration: underline;
          font-style: normal;
        }
        &.rubify-react-combo-group {
          font-weight: bold;
        }
      }
      &.combo-box-groups li {
        padding-left: 16px;
        &.rubify-react-combo-group {
          padding-left: 6px;
        }
      }
    }
  }
}

.rubify-react-light-box-container {
  &.hide-close-button {
    .rubify-react-light-box-close {
      display: none;
    }
  }
}

.PropertyViewPage {
  width: 100%;
}

.select__control {
  border-radius: 0;
  height: 52px;
  border: solid 1px #000;
}

.popupbox-btn--close {
  font-size: 18px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  top: 0;
  right: 0;
  padding: 8px;
}

// MDI font size classes
.mdi {
  &.mdi-14px {
    font-size: 14px;
  }
  &.mdi-16px {
    font-size: 16px;
  }
  &.mdi-18px {
    font-size: 18px;
  }
  &.mdi-20px {
    font-size: 20px;
  }
  &.mdi-24px {
    font-size: 24px;
  }
  &.mdi-28px {
    font-size: 28px;
  }
  &.mdi-30px {
    font-size: 30px;
  }
  &.mdi-36px {
    font-size: 36px;
  }

  &.mdi-40px {
    font-size: 40px;
  }

  &.circle {
    background-color: #15254c;
    color: #fff;
    border-radius: 50%;
  }
}

.CustomerProfile,
.Property {
  .header-section {
    padding: 20px 0px;
    .title {
      font-size: 23px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      margin-bottom: 20px;
      display: inline-block;
      text-transform: uppercase;
    }
  }
}

.player .vp-controls .custom-logo {
  max-width: 60px !important;
  height: 30px !important;
  img {
    max-width: 60px !important;
    max-height: 30px !important;
  }
}


