
.Invitation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 40px;
  background-color: #f1f1f1;
  max-width: 600px;
  width: 100%;

  .invitation-content {
    text-align: center;
    
  }
}
