.SwitchButton {
  &.loading {
    pointer-events: none;
    opacity: 0.7;

    input:checked + .slider {
    background-color: #ccc;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #ccc;
  }
  }

  
  label.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  input[type='checkbox'] {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    display: flex;
    &.round {
      border-radius: 999px;
      &:before {
        border-radius: 999px;
      }
    }

    &:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .fa.fa-spin {
      position: absolute;
      left: 9px;
      align-self: center;
    }
  }

  input:checked + .slider {
    background-color: #3f69b5;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #3f69b5;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  input:checked + .slider {
    .fa.fa-spin {
      left: unset;
      right: 9px;
    }
  }
}
