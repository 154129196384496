.ImageCropper {
  .popupbox-content {
    padding: 0;
    padding-bottom: 10px;
  }

  .image-wrapper {
    position: relative;
    .control-buttons {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      display: flex;
      height: auto;
      .rotate-button {
        width: 44px;
        display: flex;
        border: solid 1px #999;
        border-radius: 4px;
        padding: 5px;
        margin-right: 5px;
        background: #ddd;
        color: #555;
        font-size: 24px;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: #fff;
        }
      }
    }
  }
}
