
.SubscriptionPlanPage {
  flex: 1;
  width: 100%;
}
.SubscriptionPlan {
  padding-top: 40px;
  .title {
    font-size: 23px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    margin-bottom: 20px;
    display: inline-block;
    text-transform: uppercase;
  }
  .subscription-plans {
    margin-top: 30px;
    .plans-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;

      .plans-item {
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        width: 250px;
        height: 320px;
        .plan-name {
          font-weight: bold;
          margin-bottom: 20px;
          margin-top: 15px;
          font-size: 18px;
          text-align: center;
        }
        .plan-desc {
          margin-bottom: 12px;
          font-size: 16px;
          text-align: center;
        }
        .plan-sign-up {
          margin-top: auto;
          margin-bottom: 20px;
          padding-top: 40px;
          .btn-sign-up-now {
            font-size: 18px;
            cursor: pointer;
            border: solid 2px #ac2430a3;
            padding: 12px 18px;
            border-radius: 4px;
            background-color: #fff;
            color: #ac2430;
          }
        }
      }
    }
  }
}